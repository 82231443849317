import ParticipantsRequest from '@/@api/participants'
import { ref, computed } from '@vue/composition-api'
import { downloadExcel } from '@/@core/utils/excel'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const perticipants = ref([])

export default function useParticipants() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // const perticipants = ref([])

  // Table Handlers
  const tableColumns = [
    {
      label: '#',
      key: 'id',
      sortable: true,
      thStyle: { width: '100px' },
    },
    { label: 'ID', key: 'external_id', sortable: true },
    { label: 'Nombre', key: 'name', sortable: true },
    { label: 'Email', key: 'email', sortable: true },
    { label: 'Teléfono', key: 'phone', sortable: true },
    { label: 'Prémios ganados', key: 'victories', sortable: true },
    { label: 'Participaciones', key: 'participations', sortable: true },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchList = (ctx, callback) => {
    ParticipantsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(lang => ({ ...lang, loading: false })))
        totalList.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching perticipant list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchSelector(isMemo = false) {
    if (isMemo && perticipants.value.length) {
      return Promise.resolve(perticipants.value)
    }

    try {
      const { data } = await ParticipantsRequest.select()

      const list = data.data.map(lang => ({
        id: lang.id,
        value: lang.id,
        label: lang.name,
      }))

      perticipants.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function store(body) {
    try {
      const { data } = await ParticipantsRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function update(id, body) {
    try {
      const { data } = await ParticipantsRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function destroy(id) {
    try {
      const { data } = await ParticipantsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function fetchBanned() {
    try {
      const { data } = await ParticipantsRequest.banned()

      const list = data.data.map(item => ({
        id: item.id,
        value: item.id,
        label: item.name,
      }))

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function exportExcel(params = {}) {
    try {
      const response = await ParticipantsRequest.list({ params })
      const { data } = response.data.data

      downloadExcel(data, 'Participantes.xlsx')
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Ups! Ocurrio un error al exportar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // fetchSelector(true).then(list => {
  //   perticipants.value = list
  // })

  return {
    perticipants,
    fetchList,
    fetchSelector,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    store,
    update,
    destroy,
    fetchBanned,
    exportExcel,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
