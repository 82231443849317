import http from './http'
import Resource from './resource'

const path = 'admin/tombola/participants'

class Participants extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }

  banned(opt = {}) {
    return this.http.get(`api/${path}/select/banned`, opt)
  }
}

const ParticipantsRequest = new Participants(http)

export default ParticipantsRequest
